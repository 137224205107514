import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Page from '@/views/Page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    component: Page,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'factory',
        name: 'Factory',
        auth: true,
        component: () => import('../views/Factory.vue')
      },
      {
        path: 'user',
        name: 'User',
        auth: true,
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/User.vue')
      },
      {
        path: 'color',
        name: 'Color',
        auth: true,
        component: () => import('../views/Color.vue')
      },
      {
        path: 'size',
        name: 'Size',
        auth: true,
        component: () => import('../views/Size.vue')
      },
      {
        path: 'product',
        name: 'Product',
        auth: true,
        component: () => import('../views/Product.vue')
      },
      {
        path: 'production',
        name: 'Production',
        component: () => import('../views/Production.vue')
      },
      {
        path: 'finance',
        name: 'Finance',
        component: () => import('../views/Finance.vue')
      },
      {
        path: 'stock',
        name: 'Stock',
        component: () => import('../views/Stock.vue')
      },
      {
        path: 'stockHistory',
        name: 'StockHistory',
        component: () => import('../views/StockHistory.vue')
      },
      {
        path: 'productionDetail',
        name: 'ProductionDetail',
        component: () => import('../views/ProductionDetail.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
