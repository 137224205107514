export default class User {
  constructor(username, password) {
    this.username = username
    this.password = password
    this.token = ''
    this.name = ''
    this.sex = ''
    this.identity = ''
    this.mobile = ''
    this.status = ''
  }
}
