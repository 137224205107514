class Helper {
  getPageQuery(p, s) {
    if (!p) {
      p = 1
    }
    if (!s) {
      s = process.env.VUE_APP_DEFAULT_PAGE_SIZE
    }

    return `p=${p}&s=${s}`
  }
}

export default new Helper()
