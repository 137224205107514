import api from '../services/api'
import Helper from './helper'

class FactoryService {
  get(p, s) {
    return api.get(`/f?${Helper.getPageQuery(p, s)}`)
  }

  create(row) {
    return api.post('/f', row)
  }

  update(row) {
    return api.put('/f/' + row.id, row)
  }

  delete(row) {
    return api.delete('/f/' + row.id)
  }

  getCurrent() {
    return api.get('/cf')
  }

  updateCurrent(row) {
    return api.put('/cf', row)
  }
}

export default new FactoryService()
