<template>
  <div class="home">
    <FactoryInfo />
  </div>
</template>

<script>
// @ is an alias to /src
import FactoryInfo from '../components/FactoryInfo'

export default {
  name: 'Home',
  components: { FactoryInfo }
}
</script>
