export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token }
  } else {
    return {}
  }
}

function accessToken() {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return 'Bearer ' + user.token
  } else {
    return ''
  }
}

function user() {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user) {
    return user
  } else {
    return null
  }
}

export { accessToken, user }
