<template>
  <div>
    <div id="nav">
      <b-navbar toggleable="sm" type="light" variant="faded">
        <b-navbar-brand
          ><span>富腾ERP</span><small> v{{ version }}</small></b-navbar-brand
        >

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item> <router-link to="/">首页</router-link></b-nav-item>
            <b-nav-item> <router-link to="/user">员工</router-link></b-nav-item>
            <b-nav-item>
              <router-link to="/color">颜色</router-link></b-nav-item
            >
            <b-nav-item> <router-link to="/size">尺码</router-link></b-nav-item>
            <b-nav-item>
              <router-link to="/product">产品</router-link></b-nav-item
            >
            <b-nav-item>
              <router-link to="/production">制单</router-link></b-nav-item
            >
            <b-nav-item>
              <router-link to="/productionDetail">筒数</router-link></b-nav-item
            >
            <b-nav-item>
              <router-link to="/finance">工资结算</router-link></b-nav-item
            >
            <b-nav-item>
              <router-link to="/stock">库存</router-link></b-nav-item
            >
            <b-nav-item>
              <router-link to="/stockHistory">库存记录</router-link></b-nav-item
            >
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <span>你好 </span><span v-html="userDisplayName"></span>
              </template>
              <!-- <b-dropdown-item href="#">Profile</b-dropdown-item> -->
              <b-dropdown-item @click="signOut">注销</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-navbar-nav>
              <b-nav-item>
                <router-link v-if="!loggedIn" to="/login"
                  >登录</router-link
                ></b-nav-item
              >
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div v-if="alert.show">
      <b-alert :show="alert.show" :variant="alert.type">{{
        alert.message
      }}</b-alert>
    </div>
    <div class="container pb-5">
      <router-view />
    </div>
    <footer class="navbar fixed-bottom">
      <div class="w-100 text-center">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >浙ICP备2021000057号-1</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { user } from '../services/auth-header'
import FactoryService from '../services/factory.service'

export default {
  name: 'Login',
  data() {
    return {
      loggedIn: false,
      userDisplayName: '',
      version: this.appVersion || '',
      alert: {
        show: false,
        message: '',
        type: 'warning'
      }
    }
  },
  async mounted() {
    const u = user()
    if (u != null) {
      this.loggedIn = true
      this.userDisplayName = u.userDisplayName
    } else {
      this.loggedIn = false
      this.userDisplayName = ''
    }
    await this.checkValid()
  },
  methods: {
    signOut() {
      this.$store.dispatch('auth/logout').then(
        () => {
          // this.$router.push('/profile')
          this.$XModal.message({ message: '注销成功', status: 'success' })
          this.$router.push('/login')
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    },
    async checkValid() {
      const response = await FactoryService.getCurrent()
      const factoryInfo = response.data
      console.log(factoryInfo)
      if (factoryInfo.status !== 1) {
        this.alert.show = true
        if (factoryInfo.status === 0) {
          this.alert.type = 'danger'
          this.alert.message = `您的使用期限已过（${
            factoryInfo.validBefore.split(' ')[0]
          }），请联络管理员缴费以便继续使用。`
        } else if (factoryInfo.status === 2) {
          this.alert.type = 'warning'
          this.alert.message = `您的使用即将到期（${
            factoryInfo.validBefore.split(' ')[0]
          }），请联络管理员缴费以便继续使用。`
        }
      } else {
        this.alert.show = false
        this.alert.message = ''
      }
    }
  }
}
</script>
