import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import 'font-awesome/css/font-awesome.css'
import api from './services/api'
import { accessToken } from './services/auth-header'
import Print from 'vue-print-nb'
import VersionService from './services/version'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)

Vue.use(Print) // 注册
Vue.use(VXETable)
Vue.prototype.$XModal = VXETable.modal

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$http = api
api.defaults.timeout = 10000

// // 格式金额，默认2位数
// VXETable.formats.add('dateTime', ({ cellValue }, format) => {
//   return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
// })

// 自定义全局的格式化处理函数
VXETable.formats.mixin({
  // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
  dateTime ({ cellValue }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
  }
})

VXETable.setup({
  zIndex: 2000
})

api.interceptors.request.use(
  config => {
    const token = accessToken()
    if (token) {
      config.headers.common.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      // 统一处理信息并显示
      if (response.data && response.data.message) {
        VXETable.modal.message({
          message: response.data.message,
          status: 'success'
        })
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    console.log(error.response)
    // 统一处理错误信息并显示
    if (error.response && error.response.data && error.response.data.message) {
      VXETable.modal.message({
        message: error.response.data.message,
        status: 'error'
      })
    } else {
      VXETable.modal.message({
        message: '出错了',
        status: 'error'
      })
    }
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          // do something
          break

        case 401:
          // alert('session expired')
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 403:
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 404:
          // alert('page not exist')
          break
        case 502:
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
      }
      return Promise.reject(error.response)
    }
  }
)

function valiVersion() {
  return new Promise(function(resolve, reject) {
    if (process.env.NODE_ENV === 'development') {
      resolve()
      console.log('no validate version')
    } else {
      console.log('validate version')
      VersionService.getVersion().then(res => {
        const version = res.data
        const localVersion = Vue.prototype.appVersion
        if (localVersion !== undefined) {
          if (localVersion !== version) {
            VXETable.modal.alert('发现新版本，即将更新。').then(type => {
              Vue.prototype.appVersion = version
              window.location.reload()
            })
          } else {
            resolve()
          }
        } else {
          Vue.prototype.appVersion = version
          resolve()
        }
      })
    }
  })
}

router.beforeEach((to, from, next) => {
  valiVersion().then(() => {
    // router login role ect.
    next()
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
