<template>
  <div>
    <b-card body-class="p-1">
      <div class="d-flex flex-row">
        <vue-qrcode
          :margin="2"
          :scale="3"
          :value="`${backendUrl}/Emp/Create/${factoryInfo.id}`"
        />
        <div>
          <h4>{{ factoryInfo.title }}</h4>
          <b-card-text>员工在微信公众号扫描此二维码以绑定微信账号</b-card-text>
          <b-card-text
            ><b-button variant="link" @click="showForm"
              >修改名称 / 管理员密码</b-button
            ></b-card-text
          >
        </div>
      </div>
    </b-card>
    <vxe-modal
      v-model="showChangePasswordForm"
      title="编辑"
      width="400"
      min-width="300"
      min-height="150"
      :loading="submitLoading"
      resize
      destroy-on-close
    >
      <template v-slot>
        <vxe-form
          :data="formData"
          :items="formItems"
          :rules="formRules"
          title-align="right"
          title-width="160"
          @submit="submitEvent"
        ></vxe-form>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import Factory from '../models/factory'
import FactoryService from '../services/factory.service'
import VueQrcode from 'vue-qrcode'

export default {
  name: 'FactoryInfo',
  components: {
    VueQrcode
  },
  data() {
    return {
      factoryInfo: {},
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      showChangePasswordForm: false,
      submitLoading: false,
      formData: {
        id: null,
        title: null,
        adminAccount: null,
        adminPassword: null,
        adminPasswordConfirm: null
      },
      formRules: {
        title: [
          { required: true, message: '请输入名称' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符' }
        ],
        adminOldPassword: [
          { min: 6, max: 20, message: '长度在 6 到 20 个字符' }
        ],
        adminPassword: [{ min: 6, max: 20, message: '长度在 6 到 20 个字符' }],
        adminPasswordConfirm: [{ validator: this.checkPassword }]
      },
      formItems: [
        {
          field: 'title',
          title: '工厂名称',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '请输入名称' } }
        },
        {
          field: 'adminOldPassword',
          title: '管理员账号旧密码',
          span: 24,
          itemRender: {
            name: '$input',
            props: { type: 'password', placeholder: '请输入管理员账号旧密码' }
          }
        },
        {
          field: 'adminPassword',
          title: '管理员账号新密码',
          span: 24,
          itemRender: {
            name: '$input',
            props: { type: 'password', placeholder: '请输入管理员账号新密码' }
          }
        },
        {
          field: 'adminPasswordConfirm',
          title: '管理员账号新密码',
          span: 24,
          itemRender: {
            name: '$input',
            props: { type: 'password', placeholder: '请确认管理员账号新密码' }
          }
        },
        {
          align: 'center',
          span: 24,
          titleAlign: 'left',
          itemRender: {
            name: '$buttons',
            children: [
              { props: { type: 'submit', content: '提交', status: 'primary' } }
            ]
          }
        }
      ]
    }
  },
  async mounted() {
    try {
      const response = await FactoryService.getCurrent()
      this.factoryInfo = response.data
    } catch {
      this.factoryInfo = {}
    }
  },
  methods: {
    checkPassword({ itemValue }) {
      if (itemValue !== this.formData.adminPassword) {
        return new Error('两次密码输入不一致')
      }
    },
    showForm() {
      Object.assign(this.formData, this.factoryInfo)
      this.showChangePasswordForm = true
    },
    async submitEvent() {
      this.submitLoading = true
      const factory = new Factory()
      Object.assign(factory, this.formData)

      try {
        await FactoryService.updateCurrent(factory)
        this.showEdit = false
        Object.assign(this.factoryInfo, this.formData)
        this.showChangePasswordForm = false
      } catch {}
      this.submitLoading = false
    }
  }
}
</script>
