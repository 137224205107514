<template>
  <div
    class="row justify-content-md-center align-items-center"
    style="height: 90vh"
  >
    <div class="col-md-8" style="max-width: 28rem">
      <h1>登录</h1>
      <section>
        <form id="account" @submit.prevent="handleLogin">
          <hr />
          <b-form-group id="input-group-1" label="用户名：" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="user.username"
              type="text"
              placeholder="用户名"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="密码：" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="user.password"
              type="password"
              placeholder="密码"
              required
            ></b-form-input>
          </b-form-group>
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>登录</span>
          </button>
          <!-- <div class="form-group">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
          </div> -->
        </form>
      </section>
    </div>

    <footer class="navbar fixed-bottom">
      <div class="w-100 text-center">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >浙ICP备2021000057号-1</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import User from '../models/user'

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    }
  },
  methods: {
    handleLogin() {
      this.loading = true

      if (this.user.username && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            // this.$router.push('/profile')
            this.$XModal.message({ message: '登录成功', status: 'success' })
            this.$router.push(this.$route.query.redirect || '/')
            this.loading = false
          },
          (error) => {
            this.loading = false
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          }
        )
      }
    }
  }
}
</script>
